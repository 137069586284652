.loading-create-game {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100px;
	
	p {
		text-align: center;
		margin-top: 20px;
	}

	.loader {
		width: 80%;
		height: 16px;
		display: inline-block;
		background-color: #FFF;
		border: 1px solid #FFF;
		border-radius: 4px;
		background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, #94e0fc 50%, #94e0fc 75%, transparent 75%, transparent);
		font-size: 30px;
		background-size: 1em 1em;
		box-sizing: border-box;
		animation: barStripe 1s linear infinite;
	}
	
	@keyframes barStripe {
		0% {
			background-position: 1em 0;
		}
		100% {
			background-position: 0 0;
		}
	}
}

.spinner-loader {
	display: flex;
	margin: auto;
	width: 20px;
	height: 20px;
	border: 2px solid #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
